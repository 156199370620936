.open-download .report-desktop__button .open-download__button-container .open-download__button {
  width: 240px !important;
}

.open-download {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__button-container {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }

  &__button {
    width: 240px !important;
  }
}
