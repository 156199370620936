@import './themes.scss';

.form-view {
  :focus {
    outline: none;
  }

  main {
    margin: 0 -1rem;
  }
  form {
    margin-bottom: 1rem;
    .form-control-group {
      padding: 0 1rem;
      width: 100%;
      height: 80px;
      &.search-button {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        button {
          width: 186px;
          margin: 0;
          justify-content: center;
        }
      }

      &--height-unset {
        height: unset;
      }

      label {
        font-family: Roboto, sans-serif;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 14px;
      }
      @media (min-width: 1400px) {
        min-width: 367px;
      }
      @media (max-width: 1400px) {
        min-width: 267px;
      }

      .warning-message {
        margin: 0;
        color: #ffaa00;
        font-size: 11px;
        font-family: Roboto, sans-serif;
      }
      .error-message {
        color: #ff6780;
        font-size: 11px;
        font-family: Roboto, sans-serif;
      }
      .success {
        color:#40dc7e;
        border-color:#40dc7e;
      }
    }
  }

  .flex {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }

  .flex-container {
    @extend .flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .image-chooser {
    margin: 15px;
    padding-left: 1rem;
  }

  .flex-container-horizontal {
    @extend .flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .flex-container-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .flex-container-horizontal-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  footer {
    padding: 1rem;
    border-top: 1px solid #ebeef2;
    margin: 0 -1.25rem;
    display: flex;
    &.space-between {
      justify-content: space-between;
    }
    button {
      width: 120px;
      min-height: 40px;
      padding: 0;
      &.btn-success {
        &:disabled {
          border-color: #f2f2f2;
          background: #f2f2f2;
          color: #a4abb3;
          opacity: 1;

          &:hover {
            border-color: #f2f2f2;
            background: #f2f2f2 !important;
            color: #a4abb3 !important;
            opacity: 1;
          }
        }
      }
      &.longer {
        width: 170px;
        padding: 0;
      }
    }
  }

  select {
    display: block;
    min-height: 40px;
    border: 1px solid #dfe3eb;
    color: #2a2a2a;
    background-color: transparent;
    font-weight: 400;
    cursor: default;
    padding: 0.4375rem;
    font-size: 14px;
    line-height: initial;
    border-radius: 0.17rem;
    position: relative;
    width: 100%;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* hide standard arrow */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* create new arrow */
    background-image:
      linear-gradient(45deg, transparent 50%, #dfe3eb 50%),
      linear-gradient(135deg, #dfe3eb 50%, transparent 50%);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px);
    background-size:
      5px 5px,
      5px 5px;
    background-repeat: no-repeat;
  }
}

#clear-date {
  display: inline;
  margin-left: -60px;
  cursor: pointer;
  padding: 5px 10px;
}


.form-view-grid {
  .form-control-group {
    height: 68px;
  }
  .low-height{
    height: 58px;
  }
  .height-auto{
    height: auto;
  }
  .form-control-group label {
    font-family: Roboto, sans-serif;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
  }
  select {
    display: block;
    min-height: 40px;
    border: 1px solid #dfe3eb;
    color: #2a2a2a;
    background-color: transparent;
    font-weight: 400;
    cursor: default;
    padding: 0.4375rem;
    font-size: 14px;
    line-height: initial;
    border-radius: 0.17rem;
    position: relative;
    width: 100%;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, #dfe3eb 50%), linear-gradient(135deg, #dfe3eb 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
  }
  footer {
    padding: 1rem 1rem 0;
    border-top: 1px solid #ebeef2;
    margin: 0 -1.25rem;
    display: flex;
    &.space-between {
      justify-content: space-between;
    }
    button {
      width: 120px;
      min-height: 40px;
      padding: 0;
      &.btn-success {
        &:disabled {
          border-color: #f2f2f2;
          background: #f2f2f2;
          color: #a4abb3;
          opacity: 1;
        }
      }
      &.longer {
        width: 170px;
        padding: 0;
      }
    }
  }
}
