// generate .start-1
@mixin startColumnsCreator($values...) {    
  @each $val in $values {
    #{'&.start-'+$val} {
      grid-column-start: $val;
    }
  } 
}

// generate .end-1
@mixin endColumnsCreator($values...) {    
  @each $val in $values {
    #{'&.end-'+$val} {
      grid-column-end: $val;
    }
  } 
}

.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 32px;
  &.bottom-border {
    padding-bottom: 1rem;
    border-bottom: 1px solid #e4e9f2;
  }
  .box {
    @include startColumnsCreator(1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17);
    @include endColumnsCreator(1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17);
  }
}