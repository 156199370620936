ngx-ui-download-employees-report.download-employees nb-card.download-employees-report {
  width: 500px;
}

.download-employees-report {
  &__body {
    row-gap: 20px;
  }

  &__form {
    row-gap: 5px;
  }

  &__form, &__body {
    display: flex;
    flex-direction: column;
  }

  &__toggle-container {
    display: flex;
    justify-content: space-between;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__download-buttons {
    display: flex;
    column-gap: 5px;
  }
}
