// this is our just created themes.scss file, make sure the path to the file is correct
@use "themes" as *;

// framework component styles
@use "@nebular/theme/styles/all" as *;
@use "@nebular/auth/styles/globals" as *;

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./grid";
@import "./overrides";

@import "agency-report/styles";
@import "edit-care-home/styles";
@import "material/styles";
@import "nebular/styles";
@import "only-table/styles";
@import "reprt-desktop/styles";
@import "table/styles";

// global form styles
@import "./forms";

@import "~croppie/croppie.css";

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();

  // fixed in rc.9 and can be removed after upgrade

  * {
    outline: none;
    outline-style: none;
    box-shadow: none;
  }

  .cdk-global-overlay-wrapper {
    z-index: 2006 !important;
  }

  .cdk-drag.cdk-drag-preview {
    z-index: 5000 !important;
    list-style: none;
  }

  .custom-control .custom-control-indicator {
    border-radius: 50%; // TODO: quickfix for https://github.com/akveo/nebular/issues/275
  }
  .menu-button > a > * > .eva.eva-menu-2-outline {
    fill: #88b767 !important;
  }
  .btn-success {
    background-color: #88b767;
    border-color: #88b767;
  }

  .btn-info {
    background-color: #67b3ff;
    border-color: #67b3ff;

    &.in-header {
      background: white;
      border: 1px solid #d0d3d7;
      color: #d35917;
    }
  }

  .filters {
    .btn-info {
      background: white;
      border: 1px solid #d0d3d7;
      color: #d35917;
    }
  }

  .btn-danger {
    align-items: center;
    background-color: #ff6780;
    border-color: #ff6780;
  }

  .btn-warning {
    color: #fff;
    background-color: #ffa100;
    border-color: #ffa100;
  }

  .exo {
    font-family: Exo, sans-serif;
  }

  p {
    margin-bottom: 0;
  }

  .noDataToShow {
    display: flex;
    margin-top: 13rem;
    justify-content: center;
    h2 {
      background-color: #88b767;
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 0.8rem;
      font-size: 1.5rem;
    }

    &.error {
      h2 {
        background-color: #ff6780;
      }
    }
  }

  .mb-125 {
    margin-bottom: 0.125rem;
  }

  #scrollable-content {
    overflow: auto;
  }

  angular2-smart-table th.angular2-smart-actions-title-add a {
    font-size: 1.5rem;
    padding: 0;
    margin: 0.1rem;
  }

  angular2-smart-table
    thead
    tr.angular2-smart-filters
    th
    .angular2-smart-filter
    input {
    height: 27px;
    font-size: 14px;
  }

  angular2-smart-table table-cell-edit-mode {
    margin: -7.5px -5px !important;
    padding: 0.5rem 0;
  }

  angular2-smart-table {
    table tr.angular2-smart-titles th {
      padding: 0.5rem;
      padding-right: 0.5rem !important;
      font-weight: 600;
      font-size: 14px;
    }

    .angular2-smart-actions a.angular2-smart-action {
      font-size: 28px !important;
      color: #a4abb3;
    }

    thead tr.angular2-smart-filters th {
      padding: 0.175rem 0.2rem;
    }

    .form-control {
      height: 27px;
    }

    table {
      tr th {
        line-height: 15px;
        a {
          font-weight: 600;
          font-size: 14px;
        }
      }
      tbody {
        tr td {
          font-family: Exo, sans-serif;
          font-size: 14px;
          font-weight: 500;
          padding: 0.2rem 0.5rem;
        }
        tr:nth-child(2n) {
          background-color: #f5f7fc;
        }
      }
    }
  }

  .green-header {
    margin-left: 0.5rem;
    color: #88b767;
    &.pointer {
      cursor: pointer;
    }
    &.big {
      font-size: 21px;
      font-weight: 600;
      margin-left: 0;
    }
  }

  .primary-green {
    color: #88b767;
  }

  .action {
    text-align: center;
    font-weight: 500;

    &.red {
      color: red;
    }

    &.green {
      color: #88b767;
    }
  }

  .filters {
    display: flex;
    justify-content: space-between;
    &.bottom-margin {
      margin-bottom: 1rem;
    }

    a {
      text-decoration: none;
    }
  }

  button:disabled {
    background: #f2f2f2;
    color: #a4abb3;
    border: 1px solid #f2f2f2;
  }

  button:not(:disabled):not(.disabled) {
    cursor: pointer !important;
  }

  ul {
    .menu-items li {
      &:first-child {
        background: red;
      }
    }
  }

  .ng-dirty.ng-invalid {
    border-color: #dadfe6;
  }

  nb-alert {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }

  .sticky-header {
    position: sticky;
    top: 35px;
    background-color: white;
    z-index: 100;
    box-shadow: 0 2px 12px 0 #dfe3eb;
  }

  .flex {
    display: flex;
    &.middle {
      align-items: center;
    }

    &--column-gap-standard {
      column-gap: 1rem;
    }

    &--column-gap-2 {
      column-gap: 0.5rem;
    }

    &--column-gap-4 {
      column-gap: 0.25rem;
    }

    &--row-gap-3 {
      row-gap: 3px;
    }

    &--row-gap-6 {
      row-gap: 6px;
    }

    &--row-gap-standard {
      row-gap: 1rem;
    }
  }

  .display-flex {
    display: flex;
    &--column {
      flex-direction: column;
    }
    &--middle {
      align-items: center;
    }
    &--between {
      justify-content: space-between;
    }
    &--evenly {
      justify-content: space-evenly;
    }
    &--justify-center {
      justify-content: center;
    }
    &--end {
      justify-content: flex-end;
    }
    &--align-start {
      align-items: flex-start;
    }
    &--center-center {
      justify-content: center;
      align-items: center;
    }
    &--wrap-grid {
      flex-wrap: wrap;
      > * {
        flex: 0 0 100%;
        padding: 0 0.5rem;
        width: 90px;
      }
    }
    &--space-around {
      justify-content: space-around;
    }
  }

  .icon {
    &--size-20 {
      font-size: 20px;
    }
  }

  .legend {
    cursor: pointer;
    border: 1px solid #dfe3eb;
    border-radius: 0.325rem;
    height: 40px;
    background: #fff;
    color: #4b4b4b;
    font-family: Exo, sans-serif;
    font-weight: 400;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 5px;
    min-width: 80px;
    i {
      font-size: 28px;
    }

    &.small {
      height: auto;
    }
  }

  .legend-popover {
    nb-card {
      box-shadow: none;
      border-radius: 0 !important;
    }
    nb-list-item {
      display: flex;
      &:first-child {
        border-top: none;
      }
      div {
        font-weight: 700;
        margin-right: 0.5rem;
      }
      .bar {
        font-size: 11px;
        width: 37px;
        height: 21px;
        border-radius: 0.375rem;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        &.sickness {
          background: #ff6780;
        }
        &.al {
          background: #88b767;
        }
        &.to-accept {
          background: #67b3ff;
        }
      }
    }
  }

  .alert-text {
    color: #ff6780;
    text-align: center;
  }

  .error-message {
    color: #ff6780;
    font-size: 0.8rem;
    font-family: Roboto, sans-serif;
  }

  nb-card {
    width: 100%;
    margin-bottom: 0;
    border-radius: 0.375rem;
    line-height: 1rem;
  }

  nb-card-header {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-family: Exo, sans-serif;
    height: 45px;
    font-size: 16px;
    .buttons {
      display: none !important;
    }
  }

  nb-layout .layout nb-layout-header nav {
    box-shadow: 0 2px 12px 0 #dfe3eb;
    height: 3.25rem;
  }

  .mat-header-cell {
    font-size: 14px;
  }

  .nb-theme-corporate [nbInput].size-medium:not(.input-full-width) {
    max-width: 100%;
  }

  ::ng-deep nb-tabset {
    span {
      text-transform: none;
      font-size: 0.95rem;
    }
  }

  ::ng-deep nb-calendar-range {
    nb-card-header {
      height: 60px;
      .header {
        font-family: Exo, sans-serif;
        .title {
          font-weight: 600;
        }
        .sub-title {
          color: #a4abb3;
          font-weight: 200;
          font-size: 14px;
        }
      }
    }
    nb-calendar-pageable-navigation {
      [nbButton].size-small nb-icon {
        font-size: 2rem;
        height: 28px;
        width: 28px;
        color: #a4abb3;
        font-weight: 200;
      }
      [nbButton].appearance-filled.status-primary {
        font-weight: 500;
        height: 2.875rem;
        width: 9rem;
      }
    }

    nb-calendar-day-picker {
      &.medium .day-cell {
        width: 2rem;
        height: 2.5rem;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        &.today {
          background-color: #e9edf2;
          color: #4b4b4b;
        }
      }
      .range-cell.in-range {
        background: #ebfbf2;
      }
      .range-cell.start {
        background: #ebfbf2;
        font-weight: 500;
        .day-cell {
          font-weight: 500;
        }
      }
      .range-cell.end {
        background: #fff;
        .day-cell {
          font-weight: 500;
        }
      }
      .days-container {
        padding: 0 1rem 1rem 1rem;
        nb-calendar-days-names {
          background: #fff;
          border-top: 0;
          border-bottom: 0;
          .day {
            width: 2.8rem;
            height: 2rem;
            font-weight: 400;
            font-size: 14px;
            font-family: Roboto, sans-serif;
            &.holiday {
              color: #ff6780;
            }
          }
        }
      }
    }
  }

  [nbButton]:focus {
    box-shadow: none;
  }

  nb-card-body {
    padding: 0.5rem 1.25rem 1rem;

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    //scrollbar-width: none /* for Firefox */
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px !important;
      height: 10px !important;
    }
    &::-webkit-scrollbar-track {
      background-color: #edf1f7;
      margin: 10px;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #67b3ff;
      width: 10px;
      border-radius: 20px !important;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #487db2;
    }
  }

  .pages-main-row {
    min-height: 100%;
  }

  .help {
    margin-left: 1rem;
    color: #4ca6ff;
    border: 1px solid #4ca6ff;
    border-radius: 2rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.white {
      color: white;
      border: white;
    }
  }

  //.pages-main-content {
  //  width: 100%;
  //  height: 100%;
  //  color: #a4abb3;
  //  font-family: Exo, sans-serif;
  //}

  .input-status {
    height: 100%;
    width: 100%;
    font-family: Exo, sans-serif;
    color: #fff;
    text-align: center;
    border-radius: 2.5px;
    padding: 0.4rem 0.25rem;
    &--false {
      background: #ff6780;
    }
    &--true {
      background: #88b767;
    }
    &--none {
      color: black;
    }
  }

  .staff-hour-status {
    padding: 0.2rem;
    text-align: center;
    &.orange {
      color: #ffa100;
      font-weight: bold;
    }
    &.red {
      color: #ff6780;
      font-weight: bold;
    }
    &.green {
      color: #88b767;
      font-weight: bold;
    }
    &.black {
      color: black;
      font-weight: bold;
    }
  }

  .task-tracking {
    padding: 0.2rem;
    text-align: center;
    display: flex;

    &.red {
      color: #ff6780;
      font-weight: bold;
    }
    &.green {
      color: #88b767;
      font-weight: bold;
    }
  }

  .course-status {
    height: 100%;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 0.4rem;
    border-radius: 0.375rem;
    min-height: 30px;
    &.todo {
      background: #ff6780;
    }
    &.manual-review {
      background: #9c92ff;
    }
    &.due-soon {
      background: #ffaf26;
    }
    &.valid {
      background: #88b767;
    }
  }

  .training-category {
    height: 100%;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.4rem;
    border-radius: 0.375rem;
    min-height: 30px;
    &.red {
      background: #ff6780;
    }
    &.purpule {
      background: #9c92ff;
    }
    &.amber {
      background: #ffaf26;
    }
    &.green {
      background: #88b767;
    }
  }

  .sickness-analysis {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.4rem;
    border-radius: 0.375rem;
    min-height: 30px;
  }

  .btn {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0 14px;
    min-height: 40px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    i {
      font-size: 28px;
    }

    &.small {
      min-height: 35px;
    }
  }
  .btn-remove-text-transform {
    text-transform: none;
  }
  .btn-padding-L {
    padding: 0 28px;
  }
  .btn-with-email-icon {
    &:hover {
      .email-outline-icon-text {
        color: #4b4b4b;
      }
    }
  }
  .avatar {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
    &.pointer {
      cursor: pointer;
    }
  }

  .cr-slider {
    -webkit-appearance: none;
    width: 204px;
    /*required for proper track sizing in FF*/
    max-width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: transparent;
  }

  .cr-slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    background: #88b767;
    border: 0;
    border-radius: 3px;
  }

  .cr-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #88b767;
    margin-top: -6px;
  }

  .cr-slider:focus {
    outline: none;
  }

  .cr-slider::-moz-range-track {
    width: 100%;
    height: 3px;
    background: #88b767;
    border: 0;
    border-radius: 3px;
  }

  .cr-slider::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #88b767;
    margin-top: -6px;
  }

  /*hide the outline behind the border*/
  .cr-slider:-moz-focusring {
    outline: 1px solid#88B767;
    outline-offset: -1px;
  }

  .cr-slider::-ms-track {
    width: 100%;
    height: 5px;
    background: #88b767;
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    border-color: transparent; /*leave room for the larger thumb to overflow with a transparent border */
    border-width: 6px 0;
    color: transparent; /*remove default tick marks*/
  }
  .cr-slider::-ms-fill-lower {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  .cr-slider::-ms-fill-upper {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  .cr-slider::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #88b767;
    margin-top: 1px;
  }
  .cr-slider:focus::-ms-fill-lower {
    background: rgba(0, 0, 0, 0.5);
  }
  .cr-slider:focus::-ms-fill-upper {
    background: rgba(0, 0, 0, 0.5);
  }

  .user-container {
    cursor: pointer;
  }

  .ng-select {
    .ng-select-container {
      border: 1px solid #e4e9f2;
      min-height: 40px;
    }
    .ng-value-container {
      line-height: 17px;
    }
  }

  .auth-footer {
    font-family: Roboto, sans-serif;
    small {
      font-size: 0.8rem;
      color: #aaa;
    }
  }
}

.nb-theme-corporate nb-checkbox.status-basic .custom-checkbox:hover {
  border-color: #88b767;
  &.checked {
    background-color: #fff !important;
  }
}

mat-datepicker-toggle {
  position: relative;
  margin-left: -40px;
  cursor: pointer;
  button {
    background: none;
    &:focus {
      outline: none;
    }
  }
  .mat-datepicker-toggle-default-icon {
    color: #666;
  }
}

.mat-header-cell {
  font-size: 14px;
}

mat-calendar {
  border: 2px solid#88B767;
  border-radius: 0.375rem;
  background: #ffffff;
  padding: 5px;
  font-family: Roboto, sans-serif;
  button:focus {
    outline: none;
  }
  .mat-calendar-table-header th {
    font-size: 0.75rem;
    font-weight: 400;
    color: #a4abb3;
    &[aria-label="Saturday"] {
      color: #ff6780;
    }
    &[aria-label="Sunday"] {
      color: #ff6780;
    }
  }
  .mat-calendar-body-label {
    font-weight: 600;
  }
  .mat-calendar-previous-button {
    background: none;
    color: #4b4b4b;
  }
  .mat-calendar-next-button {
    background: none;
    color: #4b4b4b;
  }
  .mat-calendar-period-button {
    background-color: #88b767;
    color: #fff;
    font-family: Exo, sans-serif;
  }
  .mat-calendar-body-disabled {
    .mat-calendar-body-cell-content {
      color: rgba(0, 0, 0, 0.38);
    }
  }
  .mat-calendar-body-cell-content {
    color: #4b4b4b;
    border: 0;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 0.375rem;
    &:hover {
      background: #88b767;
      color: #fff;
      font-weight: 600;
      border-radius: 0.375rem;
    }
  }
  .mat-calendar-body-selected {
    background: #88b767;
    color: #fff;
    font-weight: 600;
  }
  .mat-calendar-body-today {
    background: #e9edf2;
    font-weight: 600;
    color: #4b4b4b;
  }
}

.nb-theme-default .btn i.nb-e-commerce {
  font-size: 1.5rem;
}

.report-icon {
  width: 60px;
  img {
    max-width: 2rem;
  }
}

.nb-theme-default nb-sidebar nb-menu {
  margin: 0 -1.25rem 4rem -1.25rem;
}

.nb-theme-default nb-sidebar.menu-sidebar.compacted .main-btn {
  overflow: hidden;
  padding-left: 12px !important;
  margin-left: 5px;
  width: 40px !important;
}

[href="/task-management/favorite-tasks"],
[href="/task-management/global-favorite-tasks"] {
  color: #88b767 !important;
}

.nb-theme-default nb-menu .menu-item > .menu-items > .menu-item a {
  padding: 0.25rem 0.5rem;
}

.nb-theme-default nb-menu .menu-item > .menu-items > .menu-item:first-child {
  margin-top: 0;
}

nb-checkbox.table-checkbox {
  display: flex;
  justify-content: center;
  border: 0 !important;
  padding: 0 !important;
  background: transparent !important;
  .customised-control {
    padding: 0 !important;
    .customised-control-indicator {
      background: #fff !important;
    }
  }
}

html,
body {
  height: 100%;
  overflow: hidden;
}

.scrollable-container {
  max-width: 5000px !important;
  height: 100% !important;
  overflow-anchor: none;
  overflow: hidden !important;
}

// popup menu
.employee-popup-menu {
  div {
    font-family: Exo, sans-serif;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    font-size: 15px;
    color: #2a2a2a;
    font-weight: 400;
    &:hover {
      color: #fff;
      background: #88b767;
    }
    &.disabled {
      color: #dfdfdf;
      pointer-events: none;
    }
  }
}

.mt-0625 {
  margin-top: 0.625rem !important;
}

.ml125 {
  margin-left: 1.25rem;
}
.mr-3 {
  margin-right: 1rem !important;
}
.pb250px {
  padding-bottom: 250px;
}
.overflow-x-auto {
  overflow-x: auto !important;
}

.mt1rem {
  margin-top: 1rem;
}

h2.section-header {
  font-size: 14px;
  color: #88b767;
  margin-bottom: 0.75rem;
  font-weight: 600;
  font-family: Exo, sans-serif;
}

.button-no-transform {
  text-transform: none !important;
}

// google maps container visibility on modals
.pac-container {
  z-index: 1052 !important; // Must be higher than z-index of bootstrap modal
}

.reject-dialog {
  min-width: 600px;
  textarea {
    height: 150px;
  }
}

.link-success {
  color: #88b767;
}

.mat-paginator {
  .mat-icon-button {
    border-radius: 0.25rem;
    margin-right: 10px;
    background-color: #67b3ff;
    color: #fff;
  }
}

.ng-dropdown-panel.urgency-select .ng-dropdown-panel-items .ng-option {
  padding: 0;
}

.nb-theme-corporate .cdk-overlay-container {
  z-index: 1003;
}

.overview__multiple-selector .ng-dropdown-panel {
  z-index: 1000;
}

.nb-theme-corporate nb-layout.window-mode .layout nb-layout-header {
  z-index: 1001;
}

nb-layout.window-mode.with-scroll {
  height: 100%;
}

.cdk-global-scrollblock {
  overflow-y: hidden !important;
}

.mat-tab-header {
  height: 25px;
}

.mat-tab-label {
  height: 15px !important;
  .mat-tab-label-content {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    font-family: Exo, sans-serif;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: #88b767;
}

mwlResizable {
  box-sizing: border-box; // required for the enableGhostResize option to work
}

.disabled-field {
  background: #f8f8f8 !important;
}

.missing-lr {
  padding-left: 20px;
  li span {
    color: red;
    position: relative;
    left: -5px;
  }
}

.table-spinner-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
  justify-content: center;
}

.spinner-table {
  position: absolute;
  width: 100%;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.5);
}

.rota-popover-content {
  margin-top: 8px;
  background: #fff;
  border: 2px solid#88B767;
  width: 600px;
}

.nb-theme-corporate nb-tabset nb-tab::-webkit-scrollbar-thumb,
.nb-theme-corporate nb-sidebar .scrollable::-webkit-scrollbar-thumb,
.nb-theme-corporate nb-card-body::-webkit-scrollbar-thumb,
.nb-theme-corporate nb-layout .scrollable-container::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 0;
}

.nb-theme-corporate nb-tabset nb-tab::-webkit-scrollbar,
.nb-theme-corporate nb-sidebar .scrollable::-webkit-scrollbar,
.nb-theme-corporate nb-card-body::-webkit-scrollbar,
.nb-theme-corporate nb-layout .scrollable-container::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

.flipped {
  transform: rotateX(180deg);
}

.thin-echo-scrollbar-hidden {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  //scrollbar-width: none; /* for Firefox */
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: unset !important;
    height: unset !important;
    display: none;
  }
}

.pages-main-content {
  max-height: calc(100vh - 100px);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  //scrollbar-width: none; /* for Firefox */
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: #edf1f7;
    margin: 10px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #67b3ff;
    width: 10px;
    border-radius: 20px !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #487db2;
  }

  &.no-padding-top {
    padding-top: 0;
  }
}

.thin-echo-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  //scrollbar-width: none; /* for Firefox */
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: #edf1f7;
    margin: 10px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #67b3ff;
    width: 10px;
    border-radius: 20px !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #487db2;
  }
}

.thin-echo-scrollbar-5 {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  //scrollbar-width: none; /* for Firefox */
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: #edf1f7;
    margin: 10px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #67b3ff;
    width: 5px;
    border-radius: 20px !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #487db2;
  }
}

.thin-echo-scrollbar-2 {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  //scrollbar-width: none; /* for Firefox */
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 10px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #67b3ff;
    width: 2px;
    border-radius: 20px !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #487db2;
  }
}

.hour-modal-window-migration {
  width: 100%;
  padding: 38vw;
  background-color: rgba(0, 0, 0, 0.32);

  .title {
    justify-content: center;
    text-align: center;
    margin-right: initial !important;
  }
}

.hour-modal-window .title {
  justify-content: center;
  text-align: center;
  margin-right: initial !important;
}

.max-100-h {
  max-height: 100% !important;
}
.min-100-h {
  min-height: 100% !important;
}
.no-y-overflow {
  overflow-y: hidden !important;
}
.ngx-table-no-y-overflow {
  & > .table-container {
    overflow-y: hidden !important;
  }
}
.ngx-table-no-y-overflow--deep {
  & .table-container {
    overflow-y: hidden !important;
  }
}
.ngx-table-flex-one {
  & .table-container {
    flex: 1;
  }
}
.ngx-table-no-max-height {
  & > .pages-main-row {
    max-height: initial !important;
  }
}
.ngx-table-height-auto {
  & .table-container {
    height: auto !important;
  }
}

.ngx-table-container-flex {
  & .table-container {
    flex: 1 !important;
    min-height: initial !important;
  }
}
.hidden {
  display: none !important;
}

.opacity-none {
  opacity: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.green-tooltip-container {
  position: relative;

  & .green-tooltip {
    visibility: hidden;
    width: 420px;
    background-color: white;
    border: 2px#88B767 solid;
    color: #2a2a2a;
    box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.2);
    text-align: left;
    padding: 1rem 1.5rem 1.4rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    top: 175%;
    left: 20%;
    margin-left: -180px;
    position: absolute;
    z-index: 101;

    & > header,
    & > b {
      margin-bottom: 5px;
      line-height: 1.8;
      font-size: 1.12rem;
      font-weight: 600;
      letter-spacing: 0.4px;
    }

    &::after {
      content: " ";
      position: absolute;
      top: -8px;
      left: 50%;
      margin-left: -10px;
      border-width: 2px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      border-style: solid;
      background: white;
      height: 16px;
      width: 16px;
      border-color: #88b767 transparent transparent#88B767;
    }
    &--top {
      bottom: 175%;
      top: initial;
      &::after {
        content: " ";
        position: absolute;
        top: 100%;
        margin-top: -8px;
        left: 50%;
        margin-left: -10px;
        border-width: 2px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 16px;
        background: white;
        height: 16px;
        border-style: solid;
        border-color: transparent#88B767#88B767 transparent;
      }
    }
    &--bottom {
      top: 130%;
    }
    &--left {
      left: -130px;
      &::after {
        left: initial;
        right: 15%;
      }
    }
  }
  &:hover .green-tooltip {
    visibility: visible;
  }
}
.black-tooltip-container {
  position: relative;

  & .black-tooltip {
    visibility: hidden;
    width: auto;
    background-color: #2a2a2a;
    color: white;
    box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.2);
    text-align: left;
    padding: 0.75rem 1.2rem 1rem 1.2rem;
    border-radius: 3px;
    font-weight: 500;
    top: 175%;
    white-space: nowrap;
    position: absolute;
    z-index: 101;

    & > header,
    & > b {
      margin-bottom: 5px;
      line-height: 1.8;
      font-size: 1.12rem;
      font-weight: 600;
      letter-spacing: 0.4px;
    }

    &::after {
      content: " ";
      position: absolute;
      top: -8px;
      left: 50%;
      margin-left: -10px;
      border-width: 2px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      background: #2a2a2a;
      color: white;
      height: 16px;
      width: 16px;
    }
    &--top {
      bottom: 120%;
      top: initial;
      &::after {
        content: " ";
        position: absolute;
        top: 100%;
        margin-top: -8px;
        left: 50%;
        margin-left: -10px;
        border-width: 2px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 16px;
        background: #2a2a2a;
        height: 16px;
      }
    }
    &--bottom {
      top: 130%;
    }
    &--left {
      left: -130px;
      &::after {
        left: initial;
        right: 15%;
      }
    }
  }
  &:hover .black-tooltip {
    visibility: visible;
  }
}
.layout {
  height: 100%;
}

.nb-checkbox-custom {
  display: flex;
  justify-content: center;
  align-items: center;

  ::ng-deep label {
    padding: 0;
  }
}

.table-container {
  flex-grow: 1;
}

.taskboard-emails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 0.5rem 1rem !important;
  height: 100%;
  max-height: 70px;
  overflow-y: scroll;
  scroll-behavior: smooth;

  ul {
    padding-inline-start: 10px;
    margin-block-end: 0;
    li {
      text-align: start;
      font-size: 14px;

      &::marker {
        color: grey;
        font-size: 12px;
      }
    }
  }
}

.nb-theme-corporate
  nb-layout
  .layout
  .layout-container
  .main-container.main-container-fixed {
  //height: unset !important;
  //bottom: 0;
  padding-bottom: 20px;
}

.test-div {
  .nb-theme-corporate
    nb-layout
    .layout
    .layout-container
    nb-sidebar
    .main-container.main-container-fixed {
    height: 20%;
  }
}

.rota-additional__select {
  .ng-dropdown-panel-items.scroll-host {
    width: 235px;
  }
}

.rota-stat-cell__select {
  .ng-dropdown-panel-items.scroll-host {
    width: 100%;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    //scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
    }
    &::-webkit-scrollbar-track {
      background-color: #edf1f7;
      margin: 10px;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #67b3ff;
      width: 5px;
      border-radius: 20px !important;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #487db2;
    }
  }
}

.rota-migration-additional__select.ng-dropdown-panel {
  width: 300px !important;
}

.rota-migration-additional__select.ng-dropdown-panel .scroll-host {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  //scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.nb-theme-corporate
  nb-layout.window-mode
  .layout
  nb-layout-header.main__header
  nav {
  max-width: unset;
}

.edit-employee__basic-info-label {
  .mat-checkbox:not(.mat-checkbox-disabled).mat-primary
    .mat-checkbox-ripple
    .mat-ripple-element,
  .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: #88b767;
  }
}

.ngx-drop-down {
  height: 40px;
  border: 1px solid #d9d7d7;
  border-radius: 0.375rem;
  font-family: Exo, sans-serif;

  &--in-table-full {
    width: 100%;
  }
}

.cursor {
  &--pointer {
    cursor: pointer;
  }
}

nb-card.question-dialog--warning {
  border-color: #ffaa00;

  nb-card-header,
  nb-card-body p {
    color: #ffaa00;
  }

  nb-card-footer {
    button.btn-success {
      background-color: #ffaa00;
      border-color: #ffaa00;
    }
  }
}

ngx-overview
  .pages-main-row
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option {
  padding: 8px 5px;
}

::ng-deep angular2-smart-table {
  thead {
    th {
      border: 1px solid #ebeef2;
      font-weight: 400;
      font-size: 14px;

      .angular2-smart-title {
        text-align: center;
      }
      angular2-smart-table-title a {
        color: #4b4b4b !important;
      }
    }
    tr.angular2-smart-filters {
      th.angular2-smart-actions-title-add {
        display: flex;
        justify-content: center;

        a.angular2-smart-action-add-add {
          color: white;
          background-color: #88b767;
          border-radius: 5px;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  tbody {
    tr:nth-child(even) {
      background-color: #f5f7fc;
    }
    td {
      border: 1px solid #ebeef2;
      font-weight: 400;
      font-size: 14px;

      angular2-st-tbody-edit-delete {
        display: flex;
      }

      .angular2-smart-action {
        display: flex;
        justify-content: center;
      }

      angular2-smart-table-cell {
        color: #4b4b4b !important;
      }
    }
  }
}

.bottom-task-bar-relative {
  position: relative;
  overflow: hidden;
}

.over-task-list {
  z-index: 1003;
}

.pinned-tasks {
  position: absolute;
  right: 0;
  background-color: transparent;
  width: 100%;

  z-index: 99;

  .pinned-tasks-header {
    background-color: #4b4b4b;
    width: 300px;
    height: 40px;
    position: relative;
    margin-left: auto;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 12px;

    color: white;
    padding: 5px 20px;
    display: flex;
    gap: 10px;
    z-index: 102;
    position: absolute;
    top: -40px;
    right: 0;

    .pinned-tasks-header-icon {
      cursor: pointer;
      display: flex;
      align-items: center;

      i {
        font-size: 20px;
      }
    }

    .pinned-tasks-header-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;

      div span {
        color: #2ba805;
        font-weight: 500;
      }
    }

    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 40px;
      height: 30px;
      background-color: transparent;
      bottom: 0;
    }

    &:before {
      left: -40px;
      border-bottom-right-radius: 50%;
      box-shadow: 20px 0 0 0 #4b4b4b;
    }

    &:after {
      right: -40px;
      border-bottom-left-radius: 50%;
      box-shadow: -20px 0px 0 0 #4b4b4b;
    }
  }

  .pinned-tasks-content {
    width: 100%;
    border: 1px solid #4b4b4b;
    border-radius: 5px;
    cursor: row-resize;
    background-color: white;
    &.visible {
      border-top: 4px solid #4b4b4b;
    }

    .pinned-tasks-content-container {
      cursor: default;
      overflow: hidden;
      height: 0;
      transition: all 1250ms ease-in-out;
      padding: 0;

      &.visible {
        overflow: auto;
        height: 300px;
        padding: 0 10px 10px 10px;
        margin-top: 10px;
        &.resized {
          height: 600px;
        }
      }
    }
  }
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 2006 !important;
}

.actions-icon {
  cursor: pointer;
  margin-left: 5px;
}

.edit-message-template {
  .ngneat-dialog-content {
    width: 1100px !important;
    height: auto !important;
  }
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  margin-top: 5px;
}

nb-menu.context-menu {
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3);
}

.mat-tooltip.rota-tooltip {
  font-size: 14px;
}

.nb-theme-corporate nb-menu .menu-item a {
  text-align: left;
}

.app-menu-expansion-panel {
  .mat-expansion-panel-body {
    padding: 0 0 16px;
  }
  .mat-expansion-panel-header {
    padding: 0;
  }
  &.mat-expansion-panel
    .mat-expansion-panel-header.cdk-keyboard-focused:not(
      [aria-disabled="true"]
    ),
  &.mat-expansion-panel
    .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
  &.mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background-color: #fff;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cdk-overlay-backdrop {
  z-index: 2006 !important;
}

.nb-theme-corporate {
  nb-tabset.echo-tabset {
    min-height: 100%;
    .tab-link {
      text-transform: none;
      padding: 0.6rem 1rem;
      font-size: 15px;
      font-weight: 400;
    }
    .tab.active .tab-link {
      color: #000;
      font-weight: bold;
    }
    .tabset {
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 999;
    }
  }
}

.nb-theme-corporate {
  .events-table {
    table-layout: auto;
    .nb-tree-grid-header-cell,
    .nb-tree-grid-cell,
    .nb-tree-grid-footer-cell {
      padding: 10px;
      height: 1rem;
      text-align: left;
      line-height: 16px;
      font-size: 14px;
      border-color: #e0e0e0;
      cursor: pointer;
      button {
        text-align: left;
      }
    }
    .cdk-column-eventSeriesId {
      width: 40px;
    }
    .cdk-column-eventFirstDayAvailable {
      width: 135px;
    }
    .subrow {
      background-color: #e3e1e1;
      border-color: #fff;
    }
  }
}

.nb-theme-corporate {
  nb-window.expanded-body {
    nb-card-body {
      overflow: visible;
      padding: 0;
    }
  }
}

.nb-theme-corporate .ng-select.role-select {
  width: 100%;
  .ng-select-container {
    background: white;
    border: 1px solid #d0d3d7;
    color: #d35917;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-weight: 400;
    border-bottom: 1px solid #ececec;
  }
}

.nb-theme-corporate nb-option-list .option-list {
  -ms-overflow-style: none;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: #edf1f7;
    margin: 10px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #67b3ff;
    width: 5px;
    border-radius: 20px !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #487db2;
  }
}

.ng-dropdown-panel-items.scroll-host {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  //scrollbar-width: none; /* for Firefox */
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: #edf1f7;
    margin: 10px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #67b3ff;
    width: 10px;
    border-radius: 20px !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #487db2;
  }
}
