.agency-report-header__period-wrapper {
  .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 0;
  }

  .mat-form-field-infix {
    border-top: 5px solid transparent;
  }
}
