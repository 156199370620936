.subtask-editable-todo-content {
  nb-accordion nb-accordion-item nb-accordion-item-header, nb-accordion nb-accordion-item nb-accordion-item-body .item-body {
    padding: 0.1em 1.25em;
  }

  nb-accordion nb-accordion-item.subtask-editable-todo-content__accordion-item {
    background-color: transparent;
    padding-block: .3rem;
  }

  nb-accordion nb-accordion-item.subtask-editable-todo-content__accordion-item nb-accordion-item-header nb-icon.expansion-indicator {
    right: 4px;
  }
}
