.agency-report-sum-table__table .pages-main-content .table-container {
  overflow: auto;
}

.agency-report-sum-table__table .pages-main-row.row {
  margin-right: 0;
  margin-left: 0;
  max-height: 50vh;
  height: auto;
}

.agency-report-sum-table__table .pages-main-row .pages-main-content {
  padding: 0;
}
