.table-gps-photo-popover {
  width: 500px;
  height: 400px;

  &__header {
    border-bottom: 1px solid #ebeef2;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 600;
  }

  &__close {
    font-size: 2rem;
    line-height: 1;
    margin-top: -0.5rem;
    cursor: pointer;
  }
  &__content {
    margin: 1rem;
    height: 310px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    width: 400px
  }

  &__agm-map {
    width: 470px;
    height: 310px;
  }

  &__map-content {
    position: absolute;
    background: #fff;
    box-shadow: 0 2px 12px #00000029;
    border-radius: 10px;
    padding: 1rem;
    width: 405px;
    left: 25px;
    bottom: 23px;
  }
}
