.download-rota-report {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,.32);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 10px;

  &__content {
    border-radius: 4px;
    background-color: white;
    padding: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  &__filters {
    display: flex;
    margin: 20px 0;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__close-btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__close-btn {
    height: 35px;
  }

}
